<template>
  <div
    v-if="!loading"
    class="app-content content"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ title }} - <b>{{ data.evaluator.name }}</b>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to Evaluators list"
              @click="$router.push({name: 'admin.calls.evaluators.index'})"
            >
              <i data-feather="chevron-left" />
            </a>
            <!-- <a href="#" class="btn btn-icon btn-flat-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Senior call settings"><i data-feather="settings"></i></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div
            v-if="$route.params.action != 'all'"
            class="col-12"
          >
            <CompleteTable
              :sorted-field="sortedField"
              :data="data.applicants.data"
              :sort-mapping="sortMapping"
              :title="'evaluations'"
              :total="applicantsTotal"
              :fetch-path="'users/fetchEvaluationsList'"
              :filter-path="'users/filterEvaluationsList'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        v-if="field.promotion"
                        :href="$router.resolve({ name: 'researcher.administration.promotion-history.see-evaluation', params: {
                          user: field.user.id,
                          convo: field.promotion.id
                        }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View candidate report
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="field.convo"
                        @click="openCanvaCall(field)"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View call evaluation
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="field.promotion"
                        @click="openCanva(field)"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View promotion evaluation
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Applicant</label>
                  <v-select
                    v-model="filters.applicant"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    placeholder="Type to search..."
                    @search="onSearch({ name: $event, role: ['researcher', 'applicant'] }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Applicant: ${$event ? $event.name : ''}`, value: $event }, 'applicant')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Call</label>
                  <v-select
                    v-model="filters.convos"
                    multiple
                    label="title"
                    :options="convos"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Calls: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'convos')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Status applicant</label>
                  <v-select
                    v-model="filters.status"
                    multiple
                    label="name"
                    :options="Object.values(data.status)"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Evaluated</label>
                  <v-select
                    v-model="filters.evaluated"
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Evaluated', value: true},
                      {label: 'No', name: 'No evaluated', value: false},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Evaluated: ${$event ? $event.label : ''}`, value: $event }, 'evaluated')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
          <div
            v-else
            class="col-12"
          >
            <Evaluations
              v-for="action in actions"
              :key="action"
              :action="action"
              @openCanva="openCanva"
              @openCanvaCall="openCanvaCall"
            />
          </div>
        </div>
      </div>
    </div>
    <ShowFormEvaluation
      :selected="selectedItem"
      :convo-aux="selectedItem.convo"
    />
    <ShowPromotionEvaluation
      v-if="selected && selectedTitle && type"
      :title="selectedTitle"
      :data="selected"
      :type="type"
      :researcher-aux="researcher"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ShowPromotionEvaluation from '../../evaluator/promotion/evaluations/view-evaluations/ShowPromotionEvaluation.vue'
import ShowFormEvaluation from '../academy-call/components/ShowFormEvaluation.vue'
import Evaluations from './partials/Evaluations.vue'

export default {
  components: {
    Evaluations,
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ShowFormEvaluation,
    ShowPromotionEvaluation,
  },
  data() {
    return {
      actions: ['senior-call', 'academia-call', 'as-pc-member', 'as-promotion-peer', 'as-promotion-host'],
      // Promotion
      selected: {},
      selectedTitle: '',
      type: null,
      researcher: null,
      // Senior & Academia
      selectedItem: {},
      title: 'Evaluations by',
      sortedField: 'name',
      defaultFields: [
        {
          name: 'Applicant',
          checked: true,
          order: 1,
        },
        {
          name: 'Evaluated',
          checked: true,
          order: 2,
        },
        {
          name: 'Call',
          checked: true,
          order: 3,
        },
        {
          name: 'Status',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Applicant: 'name',
        Evaluated: 'graded',
        Call: 'code',
        Status: 'status',
      },
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'users/evaluationsList',
      dataView: 'users/dataEvaluationsView',
      users: 'users/fakeUsers',
      applicantsTotal: 'users/evaluationsListTotal',
      convos: 'convo/convos',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('users/saveEvaluationsData', { userId: this.$route.params.id, action: this.$route.params.action })
    await this.$store.dispatch('users/filterEvaluationsList', this.filters)

    switch (this.$route.params.action) {
      case 'senior-call':
        await this.$store.dispatch('convo/fetchSenior')
        break
      case 'academia-call':
        await this.$store.dispatch('convo/fetchAcademy')
        break
      default:
        await this.$store.dispatch('convo/fetchPromotion')
        break
    }

    await this.$store.dispatch('modals/fetchUserFields', 'evaluators-call-view')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'evaluators-call-view',
      })
    }

    this.loading = false

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    openCanvaCall(user) {
      this.selectedItem = user.user
      this.selectedItem.user_id = user.user.id
      this.selectedItem.convo = user.convo

      this.$store.dispatch('modals/toggleFormEvaluation', true)
    },
    openCanva(evaluation) {
      const data = evaluation.evaluator
      switch (evaluation.type) {
        case 'as-promotion-peer':
          data.evaluation = evaluation.evaluator
          this.selected = data
          this.selectedTitle = 'Peer'
          this.type = 3
          break
        case 'as-promotion-host':
          this.selected = evaluation.evaluator
          this.selectedTitle = 'Host'
          this.type = 1
          break
        default:
          data.evaluation = evaluation.evaluator
          this.selected = data
          this.selectedTitle = 'PC Member'
          this.type = 2
          break
      }
      this.researcher = evaluation.user
      this.$store.dispatch('modals/toggleShowEvaluation', true)
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    saveFilter(value, field) {
      if (field === 'convos' && (value && Array.isArray(value.value))) {
        value.value.map(e => {
          e.name = e.title
          return e
        })
      }
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
  },
}
</script>
